import ajax from '../utils/ajax';
import { saveLog } from '@/modules/logger';
import { delay } from '@/modules/utils';
import { AWS_CREDENTIALS } from '@/constants';

const {
  AWS_ALBUM_BUCKET_NAME,
  AWS_ACL,
  AWS_BUCKET_REGION,
  AWS_IDENTITY_POOL_ID
} = AWS_CREDENTIALS;

const updateAWSConfig = () => {
  return window.AWS.config.update({
    region: AWS_BUCKET_REGION,
    credentials: new window.AWS.CognitoIdentityCredentials({
      IdentityPoolId: AWS_IDENTITY_POOL_ID
    }),
    httpOptions: {
      timeout: 300000
    }
  });
};


export const checkAudioUploaded = async (key, size) => {
  var payload = {
    bucket: AWS_ALBUM_BUCKET_NAME,
    key
  };
  if (size != null) {
    payload.size = size;
  }
  const { data } = await ajax.post('/api/s3/status/', payload);

  return JSON.parse(data);
};

const sendVoiceToDjango = payload => {
  saveLog("in-send-to-django");
  const { name, blob } = payload;
  const data = new FormData();

  data.append('key', name);
  data.append('file', blob);

  return ajax.post('/api/s3/', data, { 'Content-Type': 'multipart/form-data' });
};

export const fetchAudioQuality = session_id =>
  ajax.post('/api/ai/audio-quality/', { session_id });

export const fetchSessionQuality = session_id =>
  ajax.post('/api/session-quality/', { session_id });


export const sendVoiceToAWS = async (payload, onUpdate) => {
  if (!window.AWS)
    return delay(200).then(() => sendVoiceToAWS(payload, onUpdate));

  await updateAWSConfig();

  const { name, blob } = payload;
  const { size } = blob;

  var status = await checkAudioUploaded(name, null);
  console.log("In send to AWS, got s3 status")
  console.log(status)
  if(status.status == true) {
    saveLog("upload-already-complete", {name, size});
    return;
  }

  const upload = new window.AWS.S3.ManagedUpload({
    partSize: 1024 * 1024 * 1024,
    queueSize: 1,
    params: {
      Bucket: AWS_ALBUM_BUCKET_NAME,
      Key: name,
      Body: blob,
      ACL: AWS_ACL
    }
  });

  upload.on('httpUploadProgress', event =>
    onUpdate(parseInt((event.loaded * 95) / event.total))
  );

  try {
    await upload.promise();

    status = await checkAudioUploaded(name, size);

    if (!status) {
      saveLog("s3-status-false");
      await sendVoiceToDjango(payload);
    }

    return onUpdate(100);
  }
  catch (error) {
    console.log(error);
    console.log("Failed upload from FE");
    await sendVoiceToDjango(payload);
  }

  // try {
  //   await upload.promise();

  //   status = await checkAudioUploaded(name, size);

  //   if (!status) {
  //     saveLog("s3-status-false");
  //     await sendVoiceToDjango(payload);
  //   }

  //   return onUpdate(100);
  // } catch (error) {
  //   const { message } = error;
  //   console.log("Error:  " + error);

  //   saveLog('aws-upload-error', { message });

  //   await sendVoiceToDjango(payload);
  // }
};
