import { sessionData } from '@/api';
import { userAgent } from '@/utils/system';
import {
  STORE_SAVE_FILLING,
  STORE_SET_FILLING,
  STORE_SET_USER_DATA,
  STORE_SET_MEDICATION_HISTORY
} from '@/constants';

export default {
  [STORE_SET_FILLING]({ commit }, payload) {
    commit('setFilling', payload);
  },

  [STORE_SAVE_FILLING]({ state, commit, dispatch }) {
    const {
      filling,
      user: { session_id }
    } = state;
    const params = {
      ...filling,
      user_agent: userAgent()
    };

    commit('setState', { loading: true });

    if (session_id) {
      return sessionData
        .put(session_id, params)
        .finally(() => commit('setState', { loading: false }));
    }

    return sessionData
      .post(params)
      .then(({ data }) =>
        dispatch(STORE_SET_USER_DATA, { session_id: data.id })
      )
      .finally(() => commit('setState', { loading: false }));
  },

  [STORE_SET_MEDICATION_HISTORY]({ state, commit }, payload) {
    commit('setState', { loading: true });

    const {
      user: { session_id }
    } = state;

    if (session_id) {
      return sessionData
        .put(session_id, payload)
        .finally(() => commit('setState', { loading: false }));
    }
  }
};
