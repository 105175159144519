import uid from "uniqid";
import {
  getSessionStorage,
  setSesstionStorage,
  removeSessionStorage,
} from "@/modules/sessionStorage";
import { getStorage, setStorage } from "@/modules/localStorage";
import { time } from "./date";

export const getSessionId = () => {
  return getSessionStorage("SID");
};

export const generateSessionId = () => {
  if (getSessionId()) return;
  const SID = uid("SID-");
  setSesstionStorage({ SID });
  setStorage({ session_time: time() });
};

export const resetSessionId = () => {
  removeSessionStorage("SID");
  generateSessionId();
};

export const getUUId = () => {
  return getStorage("UUID");
};

export const generateUUId = () => {
  if (getUUId()) return;
  const UUID = uid("UUID-");
  setStorage({ UUID });
};
