import Store from '@/store';
import { setStorage } from "@/modules/localStorage";

import { register } from "@/api"
import {
  ROUTE_PROFILE_RECORD,
  ROUTE_PROFILE_HISTORY,
  ROUTE_PROFILE_CHARACTERISTICS,
  ROUTE_PROFILE_RECOMMENDATIONS,
  ROUTE_PROFILE_SETTINGS,
  ROUTE_TODAY,
  ROUTE_PROFILE_HISTORY_DETAILED,
  STORE_INITIAL_PROGRESS,
  STORE_SESSION_ID,
  ROUTE_PROFILE_DASHBOARD,
  ROUTE_HEALTH_PT_1,
} from '@/constants';

export default [
  {
    path: '/app/record-new-sample',
    name: ROUTE_PROFILE_RECORD,
    beforeEnter: async (to, from, next) => {
      Store.dispatch(STORE_INITIAL_PROGRESS);
      await Store.dispatch(STORE_SESSION_ID)
      setStorage({"session_metrics": null});
      register(Store.state)
      return next({ name: ROUTE_TODAY });
    }
  },
  {
    path: "/app/dashboard",
    component: () => import("@/pages/profile/dashboard"),
    name: ROUTE_PROFILE_DASHBOARD,
    meta: {
      title: "Dashboard",
      requireAuth: true,
      requirePast: ROUTE_HEALTH_PT_1,
    },
  },
  {
    path: '/app/lpr',
    component: () => import('@/pages/profile/sessionHistory'),
    name: ROUTE_PROFILE_HISTORY,
    meta: {
      title: 'Session History',
      requireAuth: true,
      requirePast: ROUTE_HEALTH_PT_1
    }
  },
  {
    path: "/app/ohdata",
    component: () => import("@/pages/profile/healthData"),
    name: ROUTE_PROFILE_CHARACTERISTICS,
    meta: {
      title: "Health Data",
      requireAuth: true,
      requirePast: ROUTE_HEALTH_PT_1,
    },
  },
  {
    path: "/app/tips",
    component: () => import("@/pages/profile/recommendations"),
    name: ROUTE_PROFILE_RECOMMENDATIONS,
    meta: {
      title: "Recommendations",
      requireAuth: true,
      requirePast: ROUTE_HEALTH_PT_1,
    },
  },
  {
    path: '/app/settings',
    component: () => import('@/pages/profile/settings'),
    name: ROUTE_PROFILE_SETTINGS,
    meta: {
      title: 'Settings',
      requireAuth: true
    },
  },

  {
    path: "/app/dashboard",
    component: () => import("@/pages/profile/dashboard"),
    name: ROUTE_PROFILE_DASHBOARD,
    meta: {
      title: "Dashboard",
      requireAuth: true,
      requirePast: ROUTE_HEALTH_PT_1,
    },
  },
  {
    path: "/app/lpr/:id",
    component: () => import("@/pages/profile/sessionDetailed"),
    name: ROUTE_PROFILE_HISTORY_DETAILED,
    meta: {
      title: "Lung Performance Report™",
      requireAuth: true,
      requirePast: ROUTE_HEALTH_PT_1,
    },
  },
];
