/* eslint no-unused-vars: 0 */
import ajax from '../utils/ajax';

export const ajaxAuthByEmailAndPassword = payload => {
  return ajax.post('/api/login/', payload);
};

export const ajaxRegistrationByEmailAndPassword = payload => {
  return ajax.post('/api/signup/', payload);
};

export const ajaxChangePassword = payload => {
  return ajax.put('/api/user/change-password/', payload);
};

export const ajaxChangeEmail = payload => {
  return ajax.put('/api/user/change-email/', payload);
};

export const ajaxChangeReminders = payload => {
  return ajax.put('/api/user/change-reminders/', payload);
};

export const ajaxSuperUserSignIn = payload => {
  return ajax.post('/api/superlogin/', payload);
};

export const ajaxForgotPassword = email => {
  return ajax.post('/api/user/reset-password/', { email });
};

export const ajaxResetPassword = payload => {
  return ajax.post('/api/user/reset-password/confirm/', payload);
};

export const ajaxFetchSubscription = () => {
  return ajax.get('/api/subscription/');
};

export const ajaxUnsubscribe = ({ token }) => {
  return ajax.get(`/api/reminders/unsubscribe/?token=${token}`);
};

export const ajaxMarkTooSick = ({ token }) => {
  return ajax.get(`/api/reminders/too-sick?token=${token}`);
};
