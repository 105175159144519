export const getSessionStorage = (key) => {
  const data = window.sessionStorage.getItem(key);
  if (!data) return null;
  return JSON.parse(data);
};

export const setSesstionStorage = (payload) => {
  return Object.entries(payload).forEach(([key, data]) => {
    const string = JSON.stringify(data);
    window.sessionStorage.setItem(key, string);
  });
};

export const removeSessionStorage = (key) => {
  return window.sessionStorage.removeItem(key);
};
