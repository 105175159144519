export const UI_PHONE_MASK = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const LUNG_REPORT_TABS = {
  metrics: 'Lung Metrics',
  ela: 'ELA',
};

export const LUNG_REPORT_TYPES = {
  volume: 'Lung Volume',
  breathlessness: 'Breathlessness',
  features: 'Features'
};

export const UI_PROFILE_TABS = {
  volume: "Lung Volume",
  breathlessness: "Breathlessness",
  features: "Features",
};

export const UI_RECORD_INSTRUCTIONS = {
  video: { link: "Video Instructions", title: "Video Instructions" },
  text: { link: "Text Instructions", title: "Text Instructions" },
  example: { link: "Show Me An Example", title: "Example" },
};
