import { getStorage } from "@/modules/localStorage";
import { SESSION_TIME } from "@/constants";

/**
 * @param {num<Number>}
 * @return {num<String>}
 */
export const numToTimeFormat = (num) => (num < 10 ? `0${num}` : num);

/**
 * @return {unixtimestamp<Number>}
 */
export const time = () => parseInt(Date.now() / 1000, 10);

/**
 * @return {isExpired<Boolean>}
 */
export const isTimeExpired = () => {
  const signed = getStorage("signed");

  if (!signed) return true;
  return false;
};

/**
 * @return {isExpired<Boolean>}
 */
export const isSessionExpired = () => {
  const timestamp = getStorage("session_time");

  if (!timestamp) return false;
  if (time() - timestamp < SESSION_TIME) return false;

  return true;
};

/**
 * @param {date<String>}
 * @param {type<String>} - date || time|| datetime
 * @return {date<String>} - formatted date
 *  */
export const dateFormatted = (date, type) => {
  let opt;

  switch (type) {
    case "datetime":
      opt = {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      break;
    case "time":
      opt = { hour: "numeric", minute: "numeric" };
      break;
    case "fulltime":
      opt = { hour: "numeric", minute: "numeric", second: "numeric" };
      break;
    case "daymonth":
      opt = { month: "long", day: "numeric" };
      break;
    default:
      opt = { month: "long", day: "numeric", year: "numeric" };
  }

  return new Date(date).toLocaleString("en", opt);
};

/**
 * @return {timestamp<String>}
 */
export const timestamp = () => {
  const date = new Date();
  const day = numToTimeFormat(date.getUTCDate());
  const month = numToTimeFormat(date.getUTCMonth() + 1);
  const year = date.getUTCFullYear();
  const hours = numToTimeFormat(date.getUTCHours());
  const min = numToTimeFormat(date.getUTCMinutes());
  const sec = numToTimeFormat(date.getUTCSeconds());

  return `${month}-${day}-${year}T${hours}-${min}-${sec}`;
};

/**
 * @param {sec<Number>}
 * @return {time<String>}
 */
export const formatTime = (sec) => {
  const time = sec > 0 ? sec : 0;
  const hours = Math.floor(time / (60 * 60));
  const minutes = numToTimeFormat(Math.floor((time - hours * 3600) / 60));
  const seconds = numToTimeFormat(
    Math.floor(time - hours * 3600 - minutes * 60)
  );

  if (hours > 0) {
    return `${numToTimeFormat(hours)}:${minutes}:${seconds}`;
  }

  return `${minutes}:${seconds}`;
};
