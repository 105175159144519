export const IS_DEV = process.env.NODE_ENV === "development";

export const RECORD_TYPE = 'audio/wav';
export const RECORD_IOS_SILENCE = 10;
export const RECORD_OD_SILENCE = 100;
export const RECORD_MAX_DURATION = 90;
export const RECORD_MIN_DURATION_CB = IS_DEV ? 1 : 5;
export const RECORD_MIN_DURATION_AAH = IS_DEV ? 1 : 3;
export const RECORD_MIN_DURATION_TDB = IS_DEV ? 1 : 5;
export const RECORD_MIN_DURATION_TSC = IS_DEV ? 1 : 3;
export const RECORD_MIN_DURATION_RP = IS_DEV ? 1 : 10;
export const RECORD_MIN_DURATION_CT = IS_DEV ? 1 : 8;
export const RECORD_MIN_DURATION_FEELING_TODAY = IS_DEV ? 1 : 5;
export const RECORD_CONSTRAINS = {
  echoCancellation: false,
  noiseSuppression: false,
  autoGainControl: false
};
export const RECORD_FILE_DATA = {
  audio1: {
    key: 'calibration',
    title: 'Calibration'
  },
  audio2: {
    key: 'sustained_aah',
    title: 'Sustained "Aah"'
  },
  audio3: {
    key: 'three_deep_breaths',
    title: 'Three Deep Breaths'
  },
  audio4: {
    key: 'three_strong_coughs',
    title: 'Three Strong Coughs'
  },
  audio5: {
    key: 'reading_passage',
    title: 'Reading Passage'
  },
  audio6: {
    key: 'count_to',
    title: 'Count to 20'
  },
  // audio7: {
  //   key: 'feeling_today',
  //   title: 'How Are You Feeling Today?'
  // },
};

export const FORCE_RERECORD_POPUPS = [
  "esnr", "no_fe", "lost_stream", "no_rp", "no_wl"
]
