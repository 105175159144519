// participant
export const STORE_SET_CHARACTERISTICS = 'STORE_SET_CHARACTERISTICS';
export const STORE_SAVE_CHARACTERISTIC = 'STORE_SAVE_CHARACTERISTIC';
export const STORE_GET_CHARACTERISTICS = 'STORE_GET_CHARACTERISTICS';
export const STORE_UPDATE_CHARACTERISTIC = 'STORE_UPDATE_CHARACTERISTIC';

// fillings
export const STORE_SET_FILLING = 'STORE_SET_FILLING';
export const STORE_SAVE_FILLING = 'STORE_SAVE_FILLING';

// chronic conditions
export const STORE_SET_CHRONIC = 'STORE_SET_CHRONIC';
export const STORE_SAVE_CHRONIC = 'STORE_SAVE_CHRONIC';

// following conditions
export const STORE_SET_FOLLOWING = 'STORE_SET_FOLLOWING';
export const STORE_SAVE_FOLLOWING = 'STORE_SAVE_FOLLOWING';

// audio recording
export const STORE_SAVE_VOICE_NAME = 'STORE_SAVE_VOICE_NAME';
export const STORE_SEND_VOICE = 'STORE_SEND_VOICE';
export const STORE_SET_UPLOADING = 'STORE_SET_UPLOADING';
export const STORE_FETCH_AUDIO_QUALITY = 'STORE_FETCH_AUDIO_QUALITY';
export const STORE_FETCH_SESSION_QUALITY = 'STORE_FETCH_SESSION_QUALITY';

// user
export const STORE_SET_USER_DATA = 'STORE_SET_USER_DATA';
export const STORE_SAVE_USER_DATA = 'STORE_SAVE_USER_DATA';
export const STORE_AUTH_BY_CREDS = 'STORE_AUTH_BY_CREDS';
export const STORE_REGISTRATION = 'STORE_REGISTRATION';
export const STORE_CHANGE_PASSWORD = 'STORE_CHANGE_PASSWORD';
export const STORE_CHANGE_EMAIL = 'STORE_CHANGE_EMAIL';
export const STORE_CHANGE_REMINDERS = 'STORE_CHANGE_REMINDERS';
export const STORE_FETCH_REMINDERS = 'STORE_FETCH_REMINDERS';
export const STORE_AUTH_AS_SUPER_USER = 'STORE_AUTH_AS_SUPER_USER';

// common data
export const STORE_SET_PASSED = 'STORE_SET_PASSED';
export const STORE_INITIAL_PROGRESS = 'STORE_INITIAL_PROGRESS';
export const STORE_CLEAR_DATA = 'STORE_CLEAR_DATA';
export const STORE_SHOW_POPUP = 'STORE_SHOW_POPUP';
export const STORE_CLOSE_POPUP = 'STORE_CLOSE_POPUP';
export const STORE_SET_RECORDING = 'STORE_SET_RECORDING';
export const STORE_SET_AUDIO_SRC = 'STORE_SET_AUDIO_SRC';

// session
export const STORE_SESSION_ID = 'STORE_SESSION_ID';
export const STORE_GET_LUNG_REPORT = "STORE_GET_LUNG_REPORT";
export const STORE_GET_SESSION_HISTORY = 'STORE_GET_SESSION_HISTORY';
export const STORE_GET_LAST_SESSION = 'STORE_GET_LAST_SESSION';
export const STORE_END_SESSION = 'STORE_END_SESSION';
export const STORE_GET_AI_DATA = "STORE_GET_AI_DATA";

// dashboard
export const STORE_GET_DASHBOARD_DATA = 'STORE_GET_DASHBOARD_DATA';
export const STORE_GET_NON_COMPLETED = 'STORE_GET_NON_COMPLETED';

// medication history
export const STORE_SET_MEDICATION_HISTORY = 'STORE_SET_MEDICATION_HISTORY';

// exacerbation
export const STORE_SET_EXACERBATION = 'STORE_SET_EXACERBATION';
export const STORE_SAVE_EXACERBATION = 'STORE_SAVE_EXACERBATION';
