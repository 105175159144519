import Store from "@/store";
import Router from "@/router";
import { ajaxSaveLog } from "@/api";
import { timestamp } from "@/utils/date";
import { getSessionId, getUUId } from "@/utils/userId";

export const saveLog = (log, parameters = null, URL = null) => {
  console.log(`SaveLog: ${log}`);
  if (parameters != null) {
    try {
      console.log(`SaveLog params: ${JSON.stringify(parameters)}`);
    }
    catch {
      console.log("");
    }
  }
  const logger =
    process.env.VUE_APP_LOGGER === "true" ||
    process.env.VUE_APP_LOGGER === true;

  console.log(`VUE_APP_LOGGER = ${process.env.VUE_APP_LOGGER}`)
  console.log(logger)
  if (!logger) return Promise.resolve();
  console.log("GONNA REMOTE LOG THIS")

  const {
    state: { user },
  } = Store;

  const {
    meta: { audioNum = null },
    path,
  } = Router.app.$route;

  ajaxSaveLog({
    timestamp: timestamp(),
    UserID: user.user_id || 0,
    SessionID: user.session_id || 0,
    PromptID: audioNum || 0,
    Event: log,
    URL: URL || path,
    SID: getSessionId(),
    UUID: getUUId(),
    parameters: JSON.stringify(parameters),
  }).catch((error) => console.log(error.message));
};
