<template>
  <div class="lp-main">
    <v-triangle class="lp-triangle lp-main__triangle" />

    <div class="lp-main__header-wrapper">
      <v-header :sign-in-handler="signInHandler" />
    </div>
    <div class="lp-container lp-main__wrapper">
      <h1 class="lp-main__title">Telehealth Respiratory Monitoring</h1>
      <h4 class="lp-main__subtitle">
        Telling.ai analyzes voice and breath sounds to assess lung health
      </h4>
      <div class="lp-main__button-wrapper">
        <button
          class="button main-button lp-main__button"
          @click="showContactUs"
        >
          contact us
        </button>
      </div>
    </div>
    <div class="lp-main__devices-img" />
  </div>
</template>

<script>
import vHeader from "./header";
import vTriangle from "./triangle";

import {
  POPUP_CONTACT_US,
} from "@/constants";

export default {
  props: {
    signInHandler: { type: Function },
  },

  components: { vHeader, vTriangle },

  inject: {
    showModal: { default: function () {} },
  },

  methods: {
    showContactUs() {
      this.showModal(POPUP_CONTACT_US);
    },
  },
};
</script>