export const getStorage = (key) => {
  const data = window.localStorage.getItem(key);
  if (!data) return null;
  return JSON.parse(data);
};

export const removeStorageItem = (data) => {
  if (Array.isArray(data)) {
    return data.forEach((key) => window.localStorage.removeItem(key));
  }

  return window.localStorage.removeItem(data);
};

export const setStorage = (payload) => {
  return Object.entries(payload).forEach(([key, data]) => {
    const string = JSON.stringify(data);
    window.localStorage.setItem(key, string);
  });
};

export const clearStorage = () =>
  removeStorageItem([
    "user",
    "passed",
    "uploading",
    "signed",
    "session_time",
    "ct_sent",
  ]);
