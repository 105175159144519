<template>
  <div class="lp-research">
    <div class="lp-container lp-research__wrapper">
      <h2 class="lp-research__title">
        We hear you in ways
        <br />no one else can
      </h2>
      <div class="lp-research__text">
        <p>
          A unique framework to capture respiratory sounds in real world
          settings, providing real-time feedback and denoising to optimize data
          quality.
        </p>
        <p>
          We extract dozens of features - many undetectable by the human ear -
          to power our best-in-class algorithms.
        </p>
      </div>
    </div>

    <div class="lp-container lp-box lp-research__box">
      <div class="lp-research__box-list">
        <div class="lp-research__box-item" style="margin:auto;">
          <div class="lp-research__box-item2-title"> More about <br>our research </div>
        </div>
        <div class="lp-research__box-item">
          <div class="lp-research__logo-list">
            <a
              href="https://www.youtube.com/watch?v=IbROVlXlYzc&feature=emb_logo"
              target="_blank"
              rel="noopener noreferrer"
              class="lp-research__logo lp-research__logo-nbc"
            >
              <img src="@/assets/images/lp-NBC.png" />
            </a>
            <a
              href="https://www.nsf.gov/awardsearch/showAward?AWD_ID=2032220"
              target="_blank"
              rel="noopener noreferrer"
              class="lp-research__logo lp-research__logo-nsf"
            >
              <img src="@/assets/images/lp-NSF.png" />
            </a>
            <a
              href="https://journal.chestnet.org/article/S0012-3692(20)33695-3/fulltext"
              target="_blank"
              rel="noopener noreferrer"
              class="lp-research__logo lp-research__logo-chest"
            >
              <img src="@/assets/images/lp-Chest.png" />
            </a>
          </div>
          <div class="lp-research__logo-list">
            <a
              :href="jamaLink"
              target="_blank"
              rel="noopener noreferrer"
              class="lp-research__logo lp-research__logo-jama"
            >
              <img src="@/assets/images/lp-JAMA.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <v-triangle class="lp-triangle lp-research__triangle" />
    <div class="lp-research__spacer" />
    <div class="lp-research__lungs" />
  </div>
</template>

<script>
import vTriangle from './triangle';

export default {
  props: {
    signUpHandler: { type: Function },
  },

  data() {
    return {
      jamaLink:
        'https://jamanetwork.com/journals/jama/fullarticle/2775957' +
        '?guestAccessKey=ad7d6f45-5766-4d7e-bc31-2dec825501a7',
    };
  },

  components: { vTriangle },
};
</script>
