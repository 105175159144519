import { setStorage, clearStorage } from '@/modules/localStorage';
import { time } from '@/utils/date';
import {
  initialUser,
  initialCharacteristics,
  initialPassed,
  initialFilling,
  initialCronicConditions,
  initialUploading,
  initialPopUpCount,
  initialExacerbation
} from '../../state';

import {
  STORE_SET_PASSED,
  STORE_CLEAR_DATA,
  ROUTE_ABOUT,
  ROUTE_HEALTH_PT_1,
  ROUTE_CONSENT,
  STORE_INITIAL_PROGRESS,
  STORE_SET_USER_DATA,
  STORE_SET_CHARACTERISTICS,
  STORE_SHOW_POPUP,
  STORE_CLOSE_POPUP,
  STORE_SET_RECORDING,
  STORE_SET_AUDIO_SRC
} from '@/constants';

export default {
  [STORE_SET_PASSED]({ commit, state }, payload) {
    commit('setPassed', payload);
    setStorage({ passed: state.passed });
  },

  [STORE_INITIAL_PROGRESS]({ dispatch, commit }) {
    setStorage({
      passed: initialPassed,
      uploading: initialUploading,
      popUpCount: initialPopUpCount,
      ct_sent: false,
      session_time: time()
    });
    commit('setState', {
      passed: initialPassed,
      filling: initialFilling,
      uploading: initialUploading,
      popUpCount: initialPopUpCount,
      session_metrics: null,
      exacerbation: initialExacerbation,
      recording: false,
      audioSource: null,
      session_quality: null
    });
    dispatch(STORE_SET_PASSED, {
      [ROUTE_CONSENT]: true,
      [ROUTE_ABOUT]: true,
      [ROUTE_HEALTH_PT_1]: true
    });
    dispatch(STORE_SET_USER_DATA, { session_id: null });
    dispatch(STORE_SET_CHARACTERISTICS, { covid19: null });
  },

  [STORE_CLEAR_DATA]({ commit }) {
    clearStorage();
    console.log("In store clear data")
    return commit('setState', {
      characteristics: initialCharacteristics,
      chronic_conditions: initialCronicConditions,
      filling: initialFilling,
      passed: initialPassed,
      user: initialUser,
      uploading: initialUploading,
      popUpCount: initialPopUpCount,
      session_metrics: null,
      sessionHistory: null,
      lastSession: null,
      recording: false,
      audioSource: null,
      session_quality: null,
      exacerbation: initialExacerbation
    });
  },

  [STORE_SHOW_POPUP]({ commit }, { type, options = true }) {
    return commit('addPopup', { type, options });
  },

  [STORE_CLOSE_POPUP]({ commit }, type) {
    return commit('deletePopup', type);
  },

  [STORE_SET_RECORDING]({ commit }, recording) {
    commit('setState', { recording });
  },

  [STORE_SET_AUDIO_SRC]({ commit }, audioSource) {
    commit('setState', { audioSource });
  }
};
