// import Store from '@/store';
import Landing from "@/pages/landing";
import {
  // ROUTE_CREATE_ACCOUNT,
  ROUTE_LANDING,
  // ROUTE_LOGIN,
  // ROUTE_LOGOUT,
  // STORE_CLEAR_DATA,
  // ROUTE_CONSENT,
  // ROUTE_THANK_YOU_FOR_ENROLLING,
  // ROUTE_PROFILE_DASHBOARD,
  // ROUTE_PROFILE_HISTORY,
  // ROUTE_FORGOT_PWD,
  // ROUTE_RESET_PWD
} from '@/constants';

export default [
  // {
  //   path: '/app/logout',
  //   name: ROUTE_LOGOUT,
  //   meta: { title: 'Login' },
  //   beforeEnter: (to, from, next) => {
  //     Store.dispatch(STORE_CLEAR_DATA);
  //     return next({ name: ROUTE_LOGIN });
  //   }
  // },
  // {
  //   path: '/app/signup',
  //   name: ROUTE_CREATE_ACCOUNT,
  //   component: () => import('@/pages/createAccount'),
  //   meta: {
  //     title: 'Create Account',
  //     skipIfAuth: true,
  //     nextPath: ROUTE_LOGIN,
  //     // requirePast: ROUTE_CONSENT
  //   }
  // },
  // {
  //   path: '/app/login',
  //   name: ROUTE_LOGIN,
  //   component: () => import('@/pages/login'),
  //   meta: {
  //     title: 'Login',
  //     skipIfAuth: true,
  //     nextPath: ROUTE_PROFILE_DASHBOARD
  //   }
  // },
  // {
  //   path: '/app/thanks',
  //   name: ROUTE_THANK_YOU_FOR_ENROLLING,
  //   component: () => import('@/pages/thankYouForEnrolling'),
  //   meta: {
  //     title: 'Thank you!',
  //     skipIfAuth: true
  //   }
  // },
  // {
  //   path: '/app/forgot-password',
  //   name: ROUTE_FORGOT_PWD,
  //   component: () => import('@/pages/forgotPassword'),
  //   meta: {
  //     title: 'Forgot Password',
  //     skipIfAuth: true,
  //     nextPath: ROUTE_LOGIN
  //   }
  // },
  // {
  //   path: '/app/reset-password',
  //   name: ROUTE_RESET_PWD,
  //   component: () => import('@/pages/resetPassword'),
  //   meta: {
  //     title: 'Reset Password',
  //     skipIfAuth: true,
  //     nextPath: ROUTE_LOGIN
  //   }
  // },
  {
    path: '/*',
    name: ROUTE_LANDING,
    component: Landing
    // component: () => import('@/pages/landing'),
    // meta: {
    //   skipIfAuth: true,
    //   nextPath: ROUTE_LOGIN
    // }
  },
  // {
  //   path: '/app',
  //   redirect: { name: ROUTE_LOGIN }
  // },
  // {
  //   path: '/',
  //   redirect: { name: ROUTE_LOGIN }
  // }
];
