import { setStorage } from '@/modules/localStorage';
import { time } from '@/utils/date';
import { sessionData } from '@/api';
import { userAgent } from '@/utils/system';

import {
  ajaxAuthByEmailAndPassword,
  ajaxRegistrationByEmailAndPassword,
  ajaxChangePassword,
  ajaxChangeEmail,
  ajaxChangeReminders,
  ajaxSuperUserSignIn,
  ajaxFetchSubscription
} from '@/api';

import {
  STORE_AUTH_BY_CREDS,
  STORE_SET_USER_DATA,
  STORE_REGISTRATION,
  STORE_CHANGE_PASSWORD,
  STORE_CHANGE_EMAIL,
  STORE_CHANGE_REMINDERS,
  STORE_FETCH_REMINDERS,
  STORE_AUTH_AS_SUPER_USER,
  STORE_SET_PASSED,
  STORE_SESSION_ID,
  ROUTE_CONSENT
} from '@/constants';

export default {
  [STORE_SET_USER_DATA]({ commit, state }, payload) {
    commit('setUserData', payload);
    setStorage({ user: state.user });
  },

  [STORE_SESSION_ID]({ state, commit, dispatch }) {
    const {
      register_params=null,
      user: { session_id }
    } = state;
    const params = {
      register_params,
      user_agent: userAgent()
    };

    commit('setState', { loading: true });

    if (session_id) {
      return sessionData
        .put(session_id, params)
        .finally(() => commit('setState', { loading: false }));
    }

    return sessionData
      .post(params)
      .then(({ data }) =>
        {
          console.log("Received data from get session ID");
          console.log(data);
          console.log(JSON.stringify(data));
          dispatch(STORE_SET_USER_DATA, {
            session_id: data.id,
            get_feeling_today: data.get_feeling_today,
          });
        }
      )
      .finally(() => commit('setState', { loading: false }));
  },

  [STORE_AUTH_AS_SUPER_USER]({ state, dispatch }, payload) {
    const superToken = state.user.token;

    return ajaxSuperUserSignIn(payload).then(({ data }) =>
      dispatch(STORE_SET_USER_DATA, {
        ...data,
        superToken,
        superUserSignedIn: true,
        email: payload.email
      })
    );
  },

  [STORE_CHANGE_PASSWORD](store, payload) {
    return ajaxChangePassword(payload);
  },

  [STORE_CHANGE_EMAIL]({ dispatch }, payload) {
    return ajaxChangeEmail(payload).then(() =>
      dispatch(STORE_SET_USER_DATA, payload)
    );
  },

  [STORE_CHANGE_REMINDERS]({ dispatch }, payload) {
    return ajaxChangeReminders(payload).then(() =>
      dispatch(STORE_SET_USER_DATA, payload)
    );
  },

  [STORE_FETCH_REMINDERS]({ dispatch }) {
    return ajaxFetchSubscription().then(({ data }) => {
      const { is_subscribed_email, is_subscribed_text } = data[0];

      dispatch(STORE_SET_USER_DATA, {
        is_subscribed_email,
        is_subscribed_text
      });
    });
  },

  [STORE_AUTH_BY_CREDS]({ commit, dispatch }, { keepSigned, ...payload }) {
    commit('setState', { loading: true });

    return ajaxAuthByEmailAndPassword(payload)
      .then(({ data }) => {
        const { email } = payload;
        dispatch(STORE_SET_USER_DATA, { ...data, email });

        if (keepSigned) {
          setStorage({ signed: time() });
        }

        return { data };
      })
      .finally(() => commit('setState', { loading: false }));
  },

  [STORE_REGISTRATION]({ state, commit, dispatch }, payload) {
    commit('setState', { loading: true });

    const {
      full_name,
      phone,
      is_subscribed_text,
      is_subscribed_email
    } = state.user;

    return ajaxRegistrationByEmailAndPassword({
      full_name,
      phone,
      is_subscribed_text,
      is_subscribed_email,
      ...payload
    })
      .then(({ data }) => {
        const { email } = payload;
        dispatch(STORE_SET_USER_DATA, { ...data, email });
        return { data };
      })
      .then(() => dispatch(STORE_SET_PASSED, { [ROUTE_CONSENT]: false }))
      .finally(() => commit('setState', { loading: false }));
  }
};
