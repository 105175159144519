<template>
  <div class="lp-advantages">
    <div class="lp-container">
      <p class="lp-advantages__title">Voice Advantages</p>
      <div class="lp-advantages__list">
        <div class="lp-advantages__item">
          <div class="lp-advantages__image">
            <img src="@/assets/images/lp-phone.svg" width="24" height="36" />
          </div>
          <div class="lp-advantages__heading">Ease of Use</div>
          <div
            class="lp-advantages__text"
          >As easy as speaking into your phone and entirely non-invasive</div>
        </div>
        <div class="lp-advantages__item">
          <div class="lp-advantages__image">
            <img src="@/assets/images/lp-money.svg" width="36" height="36" />
          </div>
          <div class="lp-advantages__heading">Low Cost</div>
          <div
            class="lp-advantages__text"
          >Use the phone you already have without any professional assistance</div>
        </div>
        <div class="lp-advantages__item">
          <div class="lp-advantages__image">
            <img src="@/assets/images/lp-globe.svg" width="32" height="32" />
          </div>
          <div class="lp-advantages__heading">Ubiquitous Availability</div>
          <div class="lp-advantages__text">At home or wherever you happen to be</div>
        </div>
        <div class="lp-advantages__item">
          <div class="lp-advantages__image">
            <img src="@/assets/images/lp-time.svg" width="36" height="36" />
          </div>
          <div class="lp-advantages__heading">Instantaneous</div>
          <div class="lp-advantages__text">Results available within seconds of sample submission</div>
        </div>
        <div class="lp-advantages__item">
          <div class="lp-advantages__image">
            <img src="@/assets/images/lp-adn.svg" width="18" height="36" />
          </div>
          <div class="lp-advantages__heading">Personalized</div>
          <div class="lp-advantages__text">Your vocal and respiratory tracts are unique to you</div>
        </div>
      </div>
    </div>
    <v-triangle class="lp-triangle lp-advantages__triangle" />
    <div class="lp-advantages__phone-image" />
  </div>
</template>

<script>
import vTriangle from "./triangle";

export default {
  components: { vTriangle }
};
</script>
