import { setStorage, getStorage } from '@/modules/localStorage';
import {
  sendVoiceToAWS,
  fetchAudioQuality,
  fetchSessionQuality,
  sessionData
} from '@/api';
import {
  STORE_SAVE_VOICE_NAME,
  STORE_SEND_VOICE,
  STORE_SET_UPLOADING,
  STORE_FETCH_AUDIO_QUALITY,
  STORE_FETCH_SESSION_QUALITY
} from '@/constants';

export default {
  [STORE_SET_UPLOADING]({ commit, state }, { key, payload }) {
    console.log("in store set uploading")
    console.log(key, payload)
    commit('setUploaded', { [key]: payload });
    setStorage({ uploading: state.uploading });
  },

  [STORE_SEND_VOICE]({ dispatch }, payload) {
    const { key } = payload;

    console.log("in store send voice")
    var s = getStorage("uploading")
    var key_name = s[key]["name"]
    console.log(s)
    console.log(key)
    console.log(s[key])

    dispatch(STORE_SET_UPLOADING, {
      key,
      payload: { loading: true, error: null, uploaded: false , name : key_name}
    });

    return sendVoiceToAWS(payload, ()=>{}).then(() => {
      dispatch(STORE_SAVE_VOICE_NAME, payload);
    });
  },

  [STORE_FETCH_AUDIO_QUALITY]({ state }) {
    const {
      user: { session_id }
    } = state;

    return fetchAudioQuality(session_id);
  },

  [STORE_FETCH_SESSION_QUALITY]({ state, commit }) {
    const {
      user: { session_id }
    } = state;

    return fetchSessionQuality(session_id).then(({ data: session_quality }) =>
      commit('setState', { session_quality })
    );
  },

  [STORE_SAVE_VOICE_NAME]({ state, dispatch }, { key, name }) {
    console.log("in store send voice name")
    const {
      user: { session_id }
    } = state;
    const params = { [key]: name };

    if (!session_id) return;
    var s = getStorage("uploading")
    var key_name = s[key]["name"]

    return sessionData
      .put(session_id, params)
      .then(() => {
        dispatch(STORE_SET_UPLOADING, {
          key,
          payload: { loading: false, error: null, uploaded: true , name : key_name}
        });
      })
      .catch(e =>
        dispatch(STORE_SET_UPLOADING, {
          key,
          payload: { loading: false, error: e?.message, uploaded: false , name : key_name}
        })
      );
  }
};
