import axios from "axios";
import { getToken } from "./token";

export const headers = {
  HTTP_X_REQUESTED_WITH: "XMLHttpRequest",
  Accept: "application/json",
  "Content-Type": "application/json",
};

const ajax = axios.create({
  baseURL: "/",
  headers,
  withCredentials: true,
});

ajax.interceptors.request.use((request) => {
  if (!request.headers.Authorization) {
    request.headers.Authorization = getToken();
  }
  return request;
});

ajax.interceptors.response.use(
  (response) => response,
  (error) => {
    if (401 === error.response?.status) {
      return (window.location.href = "/app/logout");
    }

    return Promise.reject(error);
  }
);

export default ajax;
