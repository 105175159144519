<template>
  <transition name="scale" appear>
    <div class="alert scale" v-click-outside="hide">
      <div class="alert__body">
        <slot name="icon" />
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  mounted() {
    this.timeout = setTimeout(this.hide.bind(this), 5000);
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  },

  methods: {
    hide() {
      this.$emit("hide");
    },
  },
};
</script>
