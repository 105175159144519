export const SESSION_TIME = 45 * 60;
export const MIN_USER_HEIGHT = 60;
export const MAX_USER_HEIGHT = 210;
export const INCHE_VALUE = 2.54;
export const FEET_VALUE = INCHE_VALUE * 12;
export const SHARE_SITE_URL = "https://telling.ai/"
export const FILLING_INITIAL_DATA = [
  {
    type: 'cough',
    start: 'I never cough',
    end: 'I cough all the time'
  },
  {
    type: 'mucus',
    start: 'I have no phlegm (mucus) in my chest at all',
    end: 'My chest is completely full of phlegm (mucus)'
  },
  {
    type: 'chest_pain',
    label: 'Chest tightness/Pain or pressure in chest',
    start: 'My chest does not feel tight at all',
    end: 'My chest feels very tight'
  },
  {
    type: 'breathlessness',
    start: 'When I walk up a hill or a flight of stairs, I am not breathless',
    end: 'When I walk up a hill or one flight of stairs, I am very breathless'
  },
  {
    type: 'limited_activities',
    label: 'Limited activities at home',
    start: 'I am not limited doing any activities',
    end: 'I am very limited doing any activities'
  },
  {
    type: 'leaving_home',
    label: 'Confident leaving home',
    start: 'I am confident leaving my home despite my lung condition',
    end:
      'I am not at all confident leaving my home because of my lung condition'
  },
  {
    type: 'sleep_soundly',
    label: 'Sleep soundly',
    start: 'I sleep soundly',
    end: "I don't sleep soundly because of my lung condition"
  },
  {
    type: 'energy',
    start: 'I have lots of energy',
    end: 'I have no energy at all'
  }
];
