import ajax from "../utils/ajax";
import { saveLog } from '@/modules/logger';
import { STREAM } from '@/constants';
import { setStorage } from "@/modules/localStorage";

const STREAM_API_URL = STREAM.STREAM_API_URL
const STREAM_APP_ID = STREAM.STREAM_APP_ID

export const registerStream = (session_id, cat) =>
  ajax.post(`/api/register-stream/`, { session_id , cat}, {timeout: 5000})

export const getRegisterStreamPayload = (session_id, cat) =>
  ajax.post(`/api/register-stream/`, { session_id , cat, return_payload: true}, {timeout: 5000})

export const setStreamData  = (session_id, data) =>
  ajax.post(`/api/set-stream-data/`, { session_id, data })

export const directRegisterStream = (payload) => {
  try {
    return fetch(STREAM_API_URL+"register-stream-session", {
      method: "POST",
      body: JSON.stringify(payload)
    })
  }
  catch(error) {
    saveLog("stream-register-failure", { error })
  }
}

export const deregisterStream = async function (session_id, user_id) {
  await fetch(STREAM_API_URL+"complete-stream-session", {
    method: "POST",
    body: JSON.stringify({
      AppID: STREAM_APP_ID,
      UserID: user_id,
      SID: session_id
    }),
    headers: {"Content-type": "application/json; charset=UTF-8"}
  }).then(response => {
    saveLog("stream-deregister-sent", { response });
  });
}

export const register = async (state) => {
  console.log(`calling register with state ${state.user.session_id}`)

  var payload = await getRegisterStreamPayload(state.user.session_id, state.filling)
  console.log("payload from registration backend")
  console.log(payload)
  state.streamAPIPayload = payload.data;

  var promise = directRegisterStream(payload.data)

  // var promise = registerStream(state.user.session_id, state.filling);
  console.log("Registration promise:");
  console.log(promise)
  state.StreamRegistrationPromise = promise;
  promise.then((e) => {
    console.log(e);
    console.log("callback registered through api/register method, completed registration promise");
    if (e.status == 200) {
      state.StreamerRegistered = true;
      saveLog("stream-registration-success");
      setStorage({ "stream-registration-status" : true });
    }
  }).catch((e) => {
    console.log(e);
    saveLog("stream-registration-failure", {stack_trace: e});
    setStorage({ "stream-registration-status" : false });
    state.StreamerRegistered = null;
  })
  console.log("Sent registration request and registered handlers to promise")
  return promise
}

export const restInference = async (state) => {
  var payload = state.streamAPIPayload;
  console.log("In restInference API call frontend")
  console.log(payload)

}
