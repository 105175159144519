<template>
  <div class="lp-footer">
    <div class="lp-container">
      <div class="lp-footer__wrapper">
        <img
          class="lp-footer__logo logo"
          src="@/assets/images/telling-logo-black.png"
          width="128"
          height="30"
          alt="footer logo"
        />
        <div>
          <div class="lp-footer__heading">COMPANY</div>
          <nav class="lp-footer__nav">
            <ul class="lp-footer__nav-list" style="padding-left: 0px;">
              <li class="lp-footer__nav-item">
                <span
                  class="lp-footer__nav-link link-show-privacy"
                  @click="showPrivacy"
                  >Privacy Policy</span
                >
              </li>
              <li class="lp-footer__nav-item">
                <span
                  class="lp-footer__nav-link link-show-terms"
                  @click="showTerms"
                  >Terms of Use</span
                >
              </li>
              <li class="lp-footer__nav-item">
                <span
                  class="lp-footer__nav-link link-show-contact"
                  @click="showContactUs"
                  >Contact Us</span
                >
              </li>
              <li class="lp-footer__nav-item">
                <span
                  class="lp-footer__nav-link link-show-about"
                  @click="showAboutUs"
                  >About Us</span
                >
              </li>
            </ul>
          </nav>
        </div>
        <div>
          <div class="lp-footer__heading">SHARE</div>
          <alert v-if="isAlertShowed" @hide="hideAlert">
            <img
              src="@/assets/images/copy.png"
              alt="copy"
              class="alert__icon"
              slot="icon"
            />
            <div class="lp-footer__share-copied">Link copied to clipboard</div>
          </alert>
          <div class="lp-footer__share-list">
            <div class="lp-footer__share-item btn-copy" @click="copy">
              <img
                src="@/assets/images/favicon.png"
                alt="Copy link"
                class="logo logo_xs lp-footer__share-logo"
              />
              <span>Copy Link</span>
            </div>
            <a
              class="lp-footer__share-item lp-footer__share-item_fb"
              target="_blank"
              rel="noopener noreferrer"
              :href="fb"
              @click="shareHandler('facebook')"
            >
              <img
                src="@/assets/images/facebook.svg"
                alt="Copy link"
                class="logo logo_xs lp-footer__share-logo"
              />
              <span>Facebook</span>
            </a>
            <a
              class="lp-footer__share-item lp-footer__share-item_tw"
              target="_blank"
              rel="noopener noreferrer"
              :href="tw"
              @click="shareHandler('twitter')"
            >
              <img
                src="@/assets/images/twitter.svg"
                alt="Copy link"
                class="logo logo_xs lp-footer__share-logo"
              />
              <span>Twitter</span>
            </a>
          </div>
        </div>
      </div>
      <div class="lp-footer__copyright">© DeepConvo, Inc. {{ year }}</div>
    </div>
  </div>
</template>

<script>
import { copyToClipboard } from "@/modules/utils";
import Alert from "@/components/alert";
import { saveLog } from "@/modules/logger";

import {
  POPUP_CONTACT_US,
  POPUP_ABOUT_US,
  POPUP_PRIVACY,
  POPUP_TERMS,
} from "@/constants";

export default {
  components: { Alert },

  data: () => ({
    url: window.location.origin,
    isAlertShowed: false,
    year: new Date().getFullYear(),
  }),

  computed: {
    fb() {
      return `https://www.facebook.com/sharer/sharer.php?u=${this.url}`;
    },
    tw() {
      return `https://twitter.com/intent/tweet?text=${this.url}`;
    },
  },

  inject: {
    showModal: { default: function () {} },
  },

  methods: {
    showContactUs() {
      this.showModal(POPUP_CONTACT_US);
    },
    showAboutUs() {
      this.showModal(POPUP_ABOUT_US);
    },
    showPrivacy() {
      this.showModal(POPUP_PRIVACY);
    },
    showTerms() {
      this.showModal(POPUP_TERMS);
    },
    copy() {
      saveLog("share-link-copied");
      copyToClipboard(this.url);
      this.showAlert();
    },
    shareHandler(platform) {
      saveLog("share-social-media", { platform });
    },
    showAlert() {
      this.isAlertShowed = true;
    },
    hideAlert() {
      this.isAlertShowed = false;
    },
  },
};
</script>
