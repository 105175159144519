import { isIOS } from "@/utils/system";

export const delay = (ms = 100) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const objectFlat = (obj) => {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value }),
    {}
  );
};

export const copyToClipboard = (str) => {
  const el = document.createElement("textarea");
  el.classList.add("hidden");
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

export const scrolltop = (top) => {
  if (isIOS) return window.scroll(0, top);
  return window.scrollTo({ top, left: 0, behavior: "smooth" });
};

/**
 * @param {errors<Object>}
 * @return {<String>}
 */
export function formatErrorsToString(errors = {}) {
  if (typeof errors === "string") return errors;
  if (typeof errors !== "object" || !Object.keys(errors).length)
    return "Something wrong, please try again some minutes later";

  return Object.entries(errors).reduce((acc, [key, arr]) => {
    let errorsJoined;
    if (Array.isArray(arr)) errorsJoined = arr.join(", ");
    else if (typeof arr === "string") errorsJoined = arr;
    else errorsJoined = JSON.stringify(arr);
    return `${acc}<span> ${key}: ${errorsJoined}</span>`;
  }, "");
}

/**
 * @param {list<Object>} - with one of keys equal null
 * @return {list<Object>} list except null
 */
export const filterNullKey = (list) => {
  return Object.entries(list).reduce((acc, [key, value]) => {
    if (key === "null") return acc;
    return { ...acc, [key]: value };
  }, {});
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};
