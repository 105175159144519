import { dashboardData } from '@/api';
import {
  STORE_GET_DASHBOARD_DATA,
  STORE_GET_NON_COMPLETED,
  STORE_GET_AI_DATA
} from '@/constants';

export default {
  [STORE_GET_DASHBOARD_DATA]({ commit, dispatch }) {
    commit('setState', { loading: true });

    return dashboardData
      .get()
      .then(({ data }) => {
        if (data.sessions_non_completed.length > 0) {
          dispatch(STORE_GET_NON_COMPLETED, data.sessions_non_completed);
        }

        commit('setState', { dashboardData: data });
      })
      .finally(() => commit('setState', { loading: false }));
  },

  [STORE_GET_NON_COMPLETED]({ dispatch }, array) {
    return Promise.all(
      array.map(id => dispatch(STORE_GET_AI_DATA, id))
    ).then(() => dispatch(STORE_GET_DASHBOARD_DATA));
  }
};
