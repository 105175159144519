export const POPUP_CONTACT_US = "modal-contact-us";
export const POPUP_UPLOADING = "modal-uploading";
export const POPUP_ABOUT_US = "modal-about-us";
export const POPUP_TERMS = "modal-terms";
export const POPUP_PRIVACY = "modal-privacy";
export const POPUP_CHANGE_PASSWORD = "modal-change-password";
export const POPUP_CHANGE_EMAIL = "modal-change-email";
export const POPUP_CHANGE_REMINDERS = "modal-change-reminders";
export const POPUP_CONFIRMATION = "modal-confirmation";
export const POPUP_NOTIFICATION = "modal-notification";
export const POPUP_LOST_STREAM = "modal-lost-stream";
export const POPUP_SUPER_USER = "modal-super-user";
export const POPUP_WELCOME = "modal-welcome";
export const POPUP_THANK_YOU = "thank-you";
export const POPUP_DETECTED_NOISE = 'modal-detected-noise';
export const POPUP_DETECTED_LOW_EFFORT = 'modal-detected-low-effort';
export const POPUP_NO_COUGH_DETECTED = 'modal-no-cough-detected';
export const POPUP_NO_FE_DETECTED = 'modal-no-FE-detected';
export const POPUP_INSUFFICIENT_FE_DETECTED = 'modal-insufficient-FE-detected';
export const POPUP_NO_VOICE_DETECTED = 'modal-no-voice-detected';
export const POPUP_SESSION_NOISE_ERROR = 'modal-session-noise-error';
export const POPUP_SESSION_RECORDING_ERROR = 'modal-session-recording-error';
