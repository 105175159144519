import { changesData } from '@/api';
import { sessionData } from '@/api';
import {
  STORE_SET_EXACERBATION,
  STORE_SAVE_EXACERBATION,
  STORE_SET_USER_DATA
} from '@/constants';

export default {
  [STORE_SET_EXACERBATION]({ commit }, payload) {
    commit('setExacerbation', payload);
  },

  [STORE_SAVE_EXACERBATION]({ state, dispatch, commit }) {
    commit('setState', { loading: true });

    const {
      prescriptions,
      dosages,
      visited_emergency_department,
      visited_emergency_department_date,
      admitted_to_hospital,
      admitted_to_hospital_date,
      discharged_date
    } = state.exacerbation;
    console.log("In store save exacerbation")
    console.log(state)
    console.log(state.user.session_id)

    const exacerbation = {
      ...prescriptions,
      ...dosages,
      visited_emergency_department,
      admitted_to_hospital,
    };

    if (admitted_to_hospital_date != null)
      exacerbation["admitted_to_hospital_date"] = new Date(admitted_to_hospital_date)
    if (discharged_date != null)
      exacerbation["discharged_date"] = new Date(discharged_date)
    if (visited_emergency_department_date != null)
      exacerbation["visited_emergency_department_date"] = new Date(visited_emergency_department_date)

    return changesData
      .post(exacerbation)
      .then(({ data: exacerbationData }) => {
        dispatch(STORE_SET_EXACERBATION, exacerbationData);
        return sessionData
          // .post({ changes: exacerbationData.id , id: state.user.session_id})
          .put(state.user.session_id, {changes: exacerbationData.id})
          .then(({ data }) =>
            dispatch(STORE_SET_USER_DATA, { session_id: data.id })
          );
      })
      .finally(() => commit('setState', { loading: false }));
  }
};
