import { healthProfileData } from '@/api';
import { participantData } from '@/api';
import { initialCronicConditions } from '../../state';
import {
  STORE_SET_CHARACTERISTICS,
  STORE_SAVE_CHARACTERISTIC,
  STORE_SET_USER_DATA,
  STORE_GET_CHARACTERISTICS,
  STORE_SET_CHRONIC,
  STORE_UPDATE_CHARACTERISTIC
} from '@/constants';

const mapCharacteristicsToParticipantAndHealthProfile = params => {
  const participantPayload = params
    ? {
        id: params.id,
        user: params.user,
        dob_month: params.dob_month,
        dob_year: params.dob_year,
        sex: params.sex,
        ethnicity: params.ethnicity
      }
    : {};

  const healthProfilePayload = params
    ? {
        user: params.user,
        height: params.height,
        smoke: params.smoke,
        smoke_value: params.smoke_value,
        vape: params.vape,
        vape_value_ml: params.vape_value_ml,
        vape_value_mg: params.vape_value_mg,
        copd: params.copd,
        asthma: params.asthma,
        bronchiectasis: params.bronchiectasis,
        cystic_fibrosis: params.cystic_fibrosis,
        pulmonary_fibrosis: params.pulmonary_fibrosis,
        lung_cancer: params.lung_cancer,
        anxiety: params.anxiety,
        congestive_heart_failure: params.congestive_heart_failure,
        depression: params.depression,
        sinusitis: params.sinusitis,
        allergic_rhinitis: params.allergic_rhinitis,
        acid_reflux: params.acid_reflux,
        gerd: params.gerd,
        obesity: params.obesity,
        pulmonary_hypertension: params.pulmonary_hypertension,
        pulmonary_nodules: params.pulmonary_nodules,
        sarcoidosis: params.sarcoidosis,
        sleep_apnea: params.sleep_apnea,
        vocal_cord_dysfunction: params.vocal_cord_dysfunction,
        stroke: params.stroke
      }
    : {};

  return [participantPayload, healthProfilePayload];
};

export default {
  [STORE_SET_CHRONIC]({ commit }, payload) {
    commit('setChronicConditions', payload);
  },

  [STORE_GET_CHARACTERISTICS]({ state, commit, dispatch }) {
    const {
      user: { participant_id }
    } = state;
    commit('setState', { loading: true });
    let characteristics = {};

    return participantData
      .get(participant_id)
      .then(({ data: participant }) => {
        characteristics = participant[0];
        return healthProfileData.get(participant[0].health_profile);
      })
      .then(({ data: healthProfile }) => {
        const chronic = Object.keys(initialCronicConditions).reduce(
          (acc, key) => {
            const value = healthProfile[key];
            const obj = { ...acc, [key]: value };
            if (value) obj.null = false;
            delete healthProfile[key];
            return obj;
          },
          { null: true }
        );

        const results = {
          ...healthProfile,
          ...characteristics
        };

        dispatch(STORE_SET_CHARACTERISTICS, results);
        dispatch(STORE_SET_CHRONIC, chronic);
      })
      .finally(() => commit('setState', { loading: false }));
  },

  [STORE_SET_CHARACTERISTICS]({ commit }, payload) {
    commit('setCharacteristics', payload);
  },

  [STORE_SAVE_CHARACTERISTIC]({ state, commit, dispatch }, params) {
    const {
      user: { participant_id }
    } = state;
    commit('setState', { loading: true });

    const [
      participantPayload,
      healthProfilePayload
    ] = mapCharacteristicsToParticipantAndHealthProfile(params);

    if (!participant_id) {
      return healthProfileData
        .post(healthProfilePayload)
        .then(({ data: healthProfile }) => {
          const { id } = healthProfile;
          const participantParams = {
            ...participantPayload,
            health_profile: id
          };

          return participantData.post(participantParams);
        })
        .then(({ data: pData }) =>
          dispatch(STORE_SET_USER_DATA, {
            participant_id: pData.id,
            health_profile_id: pData.health_profile
          })
        )
        .catch(e => console.log(e))
        .finally(() => commit('setState', { loading: false }));
    }

    return healthProfileData
      .post(healthProfilePayload)
      .then(({ data: healthProfile }) =>
        participantData.put(participant_id, {
          ...participantPayload,
          health_profile: healthProfile.id
        })
      )
      .finally(() => commit('setState', { loading: false }));
  },

  [STORE_UPDATE_CHARACTERISTIC]({ state, commit }, params) {
    const {
      user: { participant_id, health_profile_id }
    } = state;

    commit('setState', { loading: true });

    const [
      participantPayload,
      healthProfilePayload
    ] = mapCharacteristicsToParticipantAndHealthProfile(params);

    return healthProfileData
      .put(health_profile_id, healthProfilePayload)
      .then(({ data: healthProfile }) =>
        participantData.put(participant_id, {
          ...participantPayload,
          health_profile: healthProfile.id
        })
      )
      .finally(() => commit('setState', { loading: false }));
  },
};
