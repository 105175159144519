<template>
  <div class="lp-under-attack">
    <div class="lp-container lp-under-attack__wrapper">
      <p class="lp-under-attack__section">Under Attack</p>
      <h2 class="lp-under-attack__title">
        The lungs are the only internal organ constantly exposed to the elements
      </h2>
      <div class="lp-under-attack__facts-list">
        <div class="lp-under-attack__facts-item">
          <div class="lp-under-attack__facts-image">
            <img
              src="@/assets/images/lp-cigarette.svg"
              width="40"
              height="40"
            />
          </div>
          <div class="lp-under-attack__facts-text">
            20% of US adults smoke regularly
          </div>
        </div>
        <div class="lp-under-attack__facts-item">
          <div class="lp-under-attack__facts-image">
            <img
              src="@/assets/images/lp-electronic-cigarette.svg"
              width="40"
              height="40"
            />
          </div>
          <div class="lp-under-attack__facts-text">
            25% of high school seniors vape regularly
          </div>
        </div>
        <div class="lp-under-attack__facts-item">
          <div class="lp-under-attack__facts-image">
            <img
              src="@/assets/images/lp-climate-change.svg"
              width="40"
              height="40"
            />
          </div>
          <div class="lp-under-attack__facts-text">
            Climate change has contributed to increases of wildfires and
            allergenic air pollutants
          </div>
        </div>
        <div class="lp-under-attack__facts-item">
          <div class="lp-under-attack__facts-image">
            <img src="@/assets/images/lp-virus.svg" width="40" height="40" />
          </div>
          <div class="lp-under-attack__facts-text">
            Even before considering the added impact of coronavirus, lower
            respiratory tract infections cause nearly 4 million deaths per year
            globally
          </div>
        </div>
      </div>
    </div>

    <div class="lp-container lp-box lp-under-attack__box">
      <div class="lp-under-attack__box-list">
        <div class="lp-under-attack__box-title">Massive Need</div>
        <div class="lp-under-attack__box-item">
          <div>47 million</div>
          <div>Americans live with a chronic lung disease</div>
        </div>
        <div class="lp-under-attack__box-item">
          <div>$150 billion</div>
          <div>
            The annual spend on chronic lung disease and respiratory infections
          </div>
        </div>
        <div class="lp-under-attack__box-item">
          <div>800,000+</div>
          <div>Hospitalizations per year from COPD alone</div>
        </div>
      </div>
    </div>
    <v-triangle class="lp-triangle lp-under-attack__triangle" />
    <div class="lp-under-attack__lungs" />
  </div>
</template>

<script>
import vTriangle from "./triangle";

export default {
  components: { vTriangle },
};
</script>
