import { isIOS } from "@/utils/system";

export const MSG_ERROR_INVALID_DAY = "Invalid day of month";
export const MSG_ERROR_FUTURE_DATE = "Date cann't be in the future";
export const MSG_ERROR_TOO_YOUNG_AGE = "You have to be 18 or older to sign up.";
export const MSG_ERROR_TOO_OLD_AGE = "You have to be under 95 to sign up.";

export const MSG_UNSUPPORTED = `You are using an unsupported browser. Please use ${
  isIOS ? "Safari" : "Chrome"
}.`;

export const MSG_EXPIRED_SESSION =
  "Your session is greater than 45 min. Please record new sample.";

export const MSG_OFFLINE_MODE =
  "No internet connection found. Please connect to continue.";

export const MSG_EMAIL_UPDATED = "Email Successfully Updated";
export const MSG_PASSWORD_UPDATED = "Password Successfully Updated";
export const MSG_REMINDERS_UPDATED = "Reminder Settings Successfully Updated";

export const MSG_PARTICIPANT_SAVED =
  "Update Saved - Changes will affect future LPR metrics, but past metrics are not recalculated";

export const MSG_APP_UPDATED =
  "New content is available. Click Refresh to get new changes!";

export const MSG_MESSAGE_SENT = "Message sent";

export const MSG_STREAM_LOST =
  "We detected a problem with your recording. Please try again.";

export const MSG_DETECTED_NOISE_TITLE = 'No discernable voice signal';

export const MSG_DETECTED_NOISE_TEXT =
  'Try again in a quieter location or speak louder.';

export const MSG_DETECTED_LOW_EFFORT_TITLE = 'Was that your best effort?';

export const MSG_DETECTED_LOW_EFFORT_TEXT =
  'We detected lower effort than we expected.';

export const MSG_NO_COUGH_DETECTED_TITLE = 'No coughs detected';
export const MSG_NO_COUGH_DETECTED_TEXT =
  'We were unable to process this audio properly, please inhale deeply through your nose and \
  cough without covering your mouth. Was this your best effort?';

export const MSG_NO_VOICE_DETECTED_TITLE = 'No discernable signal';
export const MSG_NO_VOICE_DETECTED_TEXT =
  'We were unable to process this audio properly, please try again. Remember to use your normal \
  speaking rate and normal speaking loudness';

export const MSG_NO_FE_DETECTED_TITLE = 'No discernable signal';
export const MSG_NO_FE_DETECTED_TEXT =
  'We were unable to process this audio properly, please try again. Remember to inhale as deeply\
  as you can through your nose and then forcefully exhale through your mouth. Blast out the air \
  as if you were blowing out candles. Repeat this three times.';

export const MSG_INSUFFICIENT_FE_DETECTED_TITLE = 'Was that your best effort?';
export const MSG_INSUFFICIENT_FE_DETECTED_TEXT =
  'We were unable to process this audio properly. Remember to inhale as deeply\
  as you can through your nose and then forcefully exhale through your mouth. Blast out the air \
  as if you were blowing out candles. Repeat this three times. Was this your best effort?';

export const MSG_SESSION_NOISE_ERROR_TITLE = "Noise Error";
export const MSG_SESSION_NOISE_ERROR_TEXT =
"We are unable to process your recordings. Please move to a quieter location and start a new session";


export const MSG_SESSION_RECORDING_ERROR_TITLE = "Recording Error";
export const MSG_SESSION_RECORDING_ERROR_TEXT =
"There was a problem with your recording session. Please try again later.";
