import { objectFlat } from '@/modules/utils';

export default {
  setState(state, payload) {
    Object.entries(payload).forEach(([key, value]) => {
      state[key] = value;
    });
  },

  setCharacteristics(state, payload) {
    state.characteristics = {
      ...state.characteristics,
      ...objectFlat(payload)
    };
  },

  setFilling(state, payload) {
    state.filling = {
      ...state.filling,
      ...objectFlat(payload)
    };
  },

  setPassed(state, payload) {
    state.passed = {
      ...state.passed,
      ...objectFlat(payload)
    };
  },

  setAILoading(state, payload) {
    state.aiLoading = {
      ...state.aiLoading,
      ...objectFlat(payload)
    };
  },

  setUploaded(state, payload) {
    state.uploading = {
      ...state.uploading,
      ...objectFlat(payload)
    };
  },

  setChronicConditions(state, payload) {
    state.chronic_conditions = payload;
  },

  setExacerbation(state, payload) {
    state.exacerbation = {
      ...state.exacerbation,
      ...payload
    };
  },

  setUserData(state, payload) {
    state.user = {
      ...state.user,
      ...objectFlat(payload)
    };
  },

  deleteSession(state, id) {
    const { sessionHistory } = state;
    if (!sessionHistory) return;
    state.sessionHistory = sessionHistory.filter(
      session => +session.id !== +id
    );
  },

  addPopup(state, { type, options }) {
    state.popups = { ...state.popups, [type]: options };
  },

  deletePopup(state, type) {
    if (!state.popups[type]) return;

    // eslint-disable-next-line
    const { [type]: k, ...popups } = state.popups;

    state.popups = popups;
  }
};
