export const userAgent = () => window.navigator.userAgent;

export const userDevice = () => {
  return userAgent()
    .replace(/^.*?\((.+?)\).+$/, "$1")
    .replace(/[^\w\d]/g, " ")
    .trim()
    .replace(/\s+/g, "_");
};

export const reloadPage = () => window.location.reload();

export const isIOS = /iPad|iPhone|iPod/i.test(userDevice());

export const isAndroid = /Android/i.test(userAgent());

export const isMobile = window.innerWidth < 768;

export const isIphone = isIOS && isMobile;

export const isInStandaloneMode = Boolean(window.navigator?.standalone);

export const isSupportedBrowser = Boolean(
  navigator?.mediaDevices?.getUserMedia
);

export const isOffline = Boolean(!navigator?.onLine);
