import state from "./state";
import mutations from "./mutations";
import actions from "./actions";

export default {
  strict: false,
  state,
  actions,
  mutations,
  getters: {
    progress: ({ passed }) => {
      const max = Object.keys(passed).length;
      const ready = Object.values(passed).filter((el) => el).length;
      return Math.floor((ready * 100) / max);
    },
  },
};
