import { saveLog } from "@/modules/logger";
import ajax from '../utils/ajax';
import { getStorage, setStorage } from "@/modules/localStorage";


export const getInference = (session_id) => {
  // setStorage({"inferenceInFlight": true})
  console.log("Sending inference call")
  console.log(getStorage("inferenceInFlight"))
  var response = ajax.post(`/api/ai/inference/`, { session_id }).finally(
    () => {
      console.log("got response for inference call")
      setStorage({"inferenceInFlight": false})
      console.log(getStorage("inferenceInFlight"))
    }
  )
  return response
}

export const sessionData = {
  get: () => ajax.get('/api/session/'),
  getLast: () => ajax.get(`api/session/last_completed/`),
  post: payload => ajax.post('/api/session/', payload),
  put: (id, payload) => ajax.put(`/api/session/${id}/`, payload)
};

export const endSession = () => {
  var session_metrics = getStorage("session_metrics");
  var lpr_available = false;
  try {
    if (session_metrics.FVC.fvc_estimated != null) lpr_available = true;
  }
  catch {
    lpr_available = false;
  }
  console.log(`LPR available from stream : ${lpr_available}`)

  saveLog("end-session-called", {session_metrics, lpr_available})
  return ajax.post('/api/session/end_session/', {metrics : session_metrics, lpr_available});
};

export const lungReport =  {
  get: id => ajax.get(`/api/session-metric/${id}/`)
}

export const dashboardData = {
  get: () => ajax.get("/api/dashboard/"),
};
