import { isSessionExpired } from '@/utils/date';
import { saveLog } from '@/modules/logger';
import { resetSessionId } from '@/utils/userId';
import {
  // ROUTE_CONSENT,
  ROUTE_CONSENT_CONSUMERAPP,
  ROUTE_ABOUT,
  // ROUTE_CREATE_ACCOUNT,
  ROUTE_HEALTH_PT_1,
  // ROUTE_MEDICATION_HISTORY,
  ROUTE_TODAY,
  // ROUTE_EXACERBATION,
  ROUTE_AUDIO_CALIBRATION,
  ROUTE_SUSTAINED_AAH,
  ROUTE_THREE_DEEP_BREATHS,
  ROUTE_THREE_STRONG_COUGHS,
  ROUTE_READING_PASSAGE,
  ROUTE_COUNT_TO_20,
  ROUTE_PROFILE_HISTORY,
  ROUTE_PROFILE_RECORD,
  ROUTE_PROFILE_HISTORY_DETAILED,
  ROUTE_UNSUBSCRIBE,
  ROUTE_TOO_SICK,
  // ROUTE_FEELING_TODAY
} from '@/constants';

const beforeEnter = (to, from, next) => {
  if (isSessionExpired()) {
    resetSessionId();
    saveLog('session-timeout');
    return next({ name: ROUTE_PROFILE_HISTORY, query: { expired: true } });
  }

  return next();
};

export default [
  // {
  //   path: '/app/feeling_today',
  //   name: ROUTE_FEELING_TODAY,
  //   component: () => import('@/pages/recording/feelingToday'),
  //   meta: {
  //     title: 'How Are You Feeling Today?',
  //     requirePast: ROUTE_COUNT_TO_20,
  //     nextPath: ROUTE_PROFILE_HISTORY_DETAILED,
  //     requireAuth: true,
  //     hasProgressBar: true,
  //     audioNum: 7
  //   },
  //   beforeEnter
  // },
  {
    path: '/app/count',
    name: ROUTE_COUNT_TO_20,
    component: () => import('@/pages/recording/countTo20'),
    meta: {
      title: 'Count To 20',
      requirePast: ROUTE_READING_PASSAGE,
      nextPath: ROUTE_PROFILE_HISTORY_DETAILED,
      requireAuth: true,
      hasProgressBar: true,
      audioNum: 6
    },
    beforeEnter
  },
  {
    path: '/app/rp',
    name: ROUTE_READING_PASSAGE,
    component: () => import('@/pages/recording/readingPassage'),
    meta: {
      title: 'Reading Passage',
      requirePast: ROUTE_THREE_STRONG_COUGHS,
      nextPath: ROUTE_COUNT_TO_20,
      requireAuth: true,
      hasProgressBar: true,
      audioNum: 5
    },
    beforeEnter
  },
  {
    path: '/app/threeStrongCoughs',
    name: ROUTE_THREE_STRONG_COUGHS,
    component: () => import('@/pages/recording/threeStrongCoughs'),
    meta: {
      title: 'Three Strong Coughs',
      requirePast: ROUTE_THREE_DEEP_BREATHS,
      nextPath: ROUTE_READING_PASSAGE,
      requireAuth: true,
      hasProgressBar: true,
      audioNum: 4
    },
    beforeEnter
  },
  {
    path: '/app/threeDeepBreaths',
    name: ROUTE_THREE_DEEP_BREATHS,
    component: () => import('@/pages/recording/threeDeepBreaths'),
    meta: {
      title: 'Three Deep Breaths',
      requirePast: ROUTE_SUSTAINED_AAH,
      nextPath: ROUTE_THREE_STRONG_COUGHS,
      requireAuth: true,
      hasProgressBar: true,
      audioNum: 3
    },
    beforeEnter
  },
  {
    path: '/app/ahh',
    name: ROUTE_SUSTAINED_AAH,
    component: () => import('@/pages/recording/sustainedAah'),
    meta: {
      title: 'Sustained "Ahh"',
      requirePast: ROUTE_AUDIO_CALIBRATION,
      nextPath: ROUTE_THREE_DEEP_BREATHS,
      requireAuth: true,
      hasProgressBar: true,
      audioNum: 2
    },
    beforeEnter
  },
  {
    path: '/app/calibrate',
    name: ROUTE_AUDIO_CALIBRATION,
    component: () => import('@/pages/recording/calibration'),
    meta: {
      title: 'Audio Calibration',
      requirePast: ROUTE_TODAY,
      nextPath: ROUTE_SUSTAINED_AAH,
      requireAuth: true,
      hasProgressBar: true,
      audioNum: 1
    },
    beforeEnter
  },
  // {
  //   path: '/app/history',
  //   name: ROUTE_MEDICATION_HISTORY,
  //   component: () => import('@/pages/history'),
  //   meta: {
  //     title: 'Inhaled Medication History',
  //     requirePast: ROUTE_TODAY,
  //     nextPath: ROUTE_AUDIO_CALIBRATION,
  //     requireAuth: true,
  //     hasProgressBar: true
  //   },
  //   beforeEnter
  // },
  {
    path: '/app/today',
    name: ROUTE_TODAY,
    component: () => import('@/pages/today'),
    meta: {
      title: 'How do you feel today?',
      requirePast: ROUTE_HEALTH_PT_1,
      nextPath: ROUTE_AUDIO_CALIBRATION,
      requireAuth: true,
      hasProgressBar: true
    },
    beforeEnter
  },
  // {
  //   path: '/app/exacerbation',
  //   name: ROUTE_EXACERBATION,
  //   component: () => import('@/pages/exacerbation'),
  //   meta: {
  //     title: 'Changes Since Last Sample',
  //     nextPath: ROUTE_TODAY,
  //     requirePast: ROUTE_HEALTH_PT_1,
  //     requireAuth: true,
  //     hasProgressBar: true
  //   },
  //   beforeEnter
  // },
  {
    path: '/app/health',
    name: ROUTE_HEALTH_PT_1,
    component: () => import('@/pages/healthPt1'),
    meta: {
      title: 'Tell us about your health',
      requirePast: ROUTE_ABOUT,
      nextPath: ROUTE_PROFILE_RECORD,
      requireAuth: true,
      hasProgressBar: true
    }
  },
  {
    path: '/app/profile',
    name: ROUTE_ABOUT,
    component: () => import('@/pages/aboutYou'),
    meta: {
      title: 'Tell us about yourself',
      requirePast: ROUTE_CONSENT_CONSUMERAPP,
      nextPath: ROUTE_HEALTH_PT_1,
      requireAuth: true,
      hasProgressBar: true
    }
  },
  {
    path: '/app/consent',
    name: ROUTE_CONSENT_CONSUMERAPP,
    component: () => import('@/pages/consent_consumerapp'),
    meta: {
      title: 'Consent',
      nextPath: ROUTE_ABOUT,
      requireAuth: true,
      hasProgressBar: true
    }
  },
  // {
  //   path: '/app/consent',
  //   name: ROUTE_CONSENT,
  //   component: () => import('@/pages/consent'),
  //   meta: {
  //     title: 'Consent',
  //     nextPath: ROUTE_CREATE_ACCOUNT,
  //     hasProgressBar: true,
  //     skipIfAuth: true
  //   }
  // },
  {
    path: '/app/unsubscribe',
    name: ROUTE_UNSUBSCRIBE,
    component: () => import('@/pages/unsubscribe'),
    meta: {
      title: 'Unsubscribe',
    },
    props: route => ({ token: route.query.token })
  },
  {
    path: '/app/unwell',
    name: ROUTE_TOO_SICK,
    component: () => import('@/pages/tooSick'),
    meta: {
      title: 'Too Sick',
    },
    props: route => ({ token: route.query.token })
  }
];
