export const ROUTE_CREATE_ACCOUNT = 'createAccount';
export const ROUTE_LOGIN = 'login';
export const ROUTE_LOGOUT = 'logout';
export const ROUTE_FORGOT_PWD = 'forgotPassword';
export const ROUTE_RESET_PWD = 'resetPassword';
export const ROUTE_THANK_YOU_FOR_ENROLLING = 'thankYouForEnrolling';
export const ROUTE_UNSUBSCRIBE = 'unsubscribe';
export const ROUTE_TOO_SICK = 'tooSick';
export const ROUTE_LANDING = 'landing';

export const ROUTE_CONSENT = 'consent';
export const ROUTE_CONSENT_CONSUMERAPP = 'consentpage';
export const ROUTE_ABOUT = 'aboutYou';
export const ROUTE_HEALTH_PT_1 = 'healthPt1';
export const ROUTE_EXACERBATION = 'exacerbation';
export const ROUTE_TODAY = 'today';
export const ROUTE_AUDIO_CALIBRATION = 'calibration';
export const ROUTE_SUSTAINED_AAH = 'sustainedAah';
export const ROUTE_THREE_DEEP_BREATHS = 'threeDeepBreaths';
export const ROUTE_THREE_STRONG_COUGHS = 'threeStrongCoughs';
export const ROUTE_READING_PASSAGE = 'readingPassage';
export const ROUTE_WORD_LIST = 'wordList';
export const ROUTE_COUNT_TO_20 = 'countTo20';
export const ROUTE_FEELING_TODAY = 'feelingToday';
export const ROUTE_THANKS = 'thanks';
export const ROUTE_MEDICATION_HISTORY = 'medicationHistory';

export const ROUTE_PROFILE_HISTORY = 'history';
export const ROUTE_PROFILE_SETTINGS = 'settings';
export const ROUTE_PROFILE_RECORD = 'recordSample';
export const ROUTE_PROFILE_HISTORY_DETAILED = 'historyDetailed';
export const ROUTE_PROFILE_DASHBOARD = 'dashboard';
export const ROUTE_PROFILE_CHARACTERISTICS = 'characteristics';
export const ROUTE_PROFILE_RECOMMENDATIONS = 'tips';

