import { sessionData, endSession, lungReport, getInference  } from '@/api';
import { saveLog } from '@/modules/logger';
import {
  STORE_GET_SESSION_HISTORY,
  STORE_GET_LAST_SESSION,
  STORE_END_SESSION,
  STORE_GET_LUNG_REPORT,
  STORE_GET_AI_DATA,
} from '@/constants';

export default {
  [STORE_GET_SESSION_HISTORY]({ commit }) {
    commit('setState', { loading: true });

    return sessionData
      .get()
      .then(({ data }) => commit('setState', { sessionHistory: data }))
      .finally(() => commit('setState', { loading: false }));
  },

  [STORE_GET_LAST_SESSION]({ commit }) {
    commit('setState', { loading: true });

    return sessionData
      .getLast()
      .then(({ data }) => commit('setState', { lastSession: data }))
      .catch(() => {  commit('setState', { lastSession: null })})
      .finally(() => commit('setState', { loading: false }));
  },

  [STORE_GET_LUNG_REPORT]({ commit }, id) {
    // commit('setState', { loading: true });

    return lungReport
      .get(id)
      .then(({ data }) => {
        commit('setState', { lungReport: data });
        return { data };
      })
      .finally(() => commit('setState', { loading: false }));
  },
  [STORE_END_SESSION]() {
    return endSession();
  },

  [STORE_GET_AI_DATA]({ state, commit }, id) {
    const { aiLoading, lungReport } = state;

    if (aiLoading[id]) return;

    commit('setAILoading', { [id]: true });

    return getInference(id)
      .then(({ data }) => {
        const logParameters = {
          report: lungReport,
          response: data,
          error_code: data?.error_code
        };

        try {
          saveLog('api-error-code', logParameters);
        }
        catch(e) {
          console.log(e)
        }


        console.log("got inference response")
        console.log(data)

        return commit('setState', { lungReport: data });
      })
      .catch(({ response }) => {
        const url = response?.config?.url;
        const errorCode = response?.data?.error_code || 1;
        const report = {
          ...lungReport,
          error_code: errorCode
        };
        const logParameters = {
          report: lungReport,
          response: response?.data,
          error_code: errorCode
        };

        saveLog('api-error-code', logParameters, url);
        commit('setState', { lungReport: report });
      })
      .finally(() => commit('setAILoading', { [id]: false }));
  }
};
