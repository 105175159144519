import Vue from 'vue';
import Store from '@/store';
import VueRouter from 'vue-router';
import appRoutes from './modules/app';
import profileRoutes from './modules/profile';
import authRoutes from './modules/auth';
import { isOffline } from '@/utils/system';
import { saveLog } from '@/modules/logger';

import {
  ROUTE_LOGIN,
  ROUTE_CONSENT,
  STORE_SHOW_POPUP,
  POPUP_NOTIFICATION,
  MSG_OFFLINE_MODE
} from '@/constants';

Vue.use(VueRouter);

const routes = [
  ...profileRoutes,
  ...appRoutes,
  ...authRoutes,
  { path: '*', redirect: { name: ROUTE_CONSENT } }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: (to, from, position) => {
    if (to.meta.savePosition) return position;
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const {
    requirePast = false,
    requireAuth = false,
    nextPath = false,
    skipIfAuth = false
  } = to.meta;
  const {
    passed = {},
    user: { token }
  } = Store.state;
  const isPreviousPassed = passed[requirePast];
  const isCurrentPassed = passed[to.name];
  let name;

  if (isOffline) {
    Store.dispatch(STORE_SHOW_POPUP, {
      type: POPUP_NOTIFICATION,
      options: { title: MSG_OFFLINE_MODE }
    });
    return next(false);
  }

  if (from.name) {
    saveLog('leave-page', null, from.path);
  }

  if (requireAuth && !token) {
    name = ROUTE_LOGIN;
  } else if ((skipIfAuth && token) || (nextPath && isCurrentPassed)) {
    name = nextPath;
  } else if (requirePast && !isPreviousPassed) {
    name = requirePast;
  }

  if (from.name === name) return next(false);

  if (name) return next({ name });

  return next();
});

export default router;
