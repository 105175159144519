import user from "./modules/user";
import chars from "./modules/characteristics";
import exacerbation from "./modules/exacerbation";
import common from "./modules/common";
import filling from "./modules/filling";
import voice from "./modules/voice";
import sessionHistory from "./modules/sessionHistory";
import dashboard from "./modules/dashboard";

export default {
  ...user,
  ...chars,
  ...common,
  ...filling,
  ...voice,
  ...exacerbation,
  ...sessionHistory,
  ...dashboard,
};
