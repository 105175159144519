import { getStorage } from "@/modules/localStorage";

export const getToken = () => {
  const { token } = getStorage("user") || {};

  if (!token) return null;
  return `Token ${token}`;
};

export const getSuperToken = () => {
  const { superToken } = getStorage("user") || {};

  if (!superToken) return null;
  return `Token ${superToken}`;
};
