<template>
  <div>
    <v-main :signInHandler="signInHandler" :signUpHandler="startNowHandler" />
    <v-description />
    <v-under-attack />
    <v-advantages />
    <v-voice-advantages />
    <v-research :signUpHandler="startNowHandler" />
    <div class="colored-spacer" />
    <v-footer />
  </div>
</template>

<script>
import vMain from "@/components/landingPage/main";
import vDescription from "@/components/landingPage/description";
import vUnderAttack from "@/components/landingPage/underAttack";
import vAdvantages from "@/components/landingPage/advantages";
import vVoiceAdvantages from "@/components/landingPage/voiceAdvantages";
import vResearch from "@/components/landingPage/research";
import vFooter from "@/components/landingPage/footer";
import { ROUTE_CREATE_ACCOUNT, ROUTE_LOGIN } from "@/constants";
import logVisiting from "@/mixins/logVisiting";

export default {
  components: {
    vMain,
    vFooter,
    vDescription,
    vUnderAttack,
    vAdvantages,
    vVoiceAdvantages,
    vResearch
  },

  mixins: [logVisiting],

  methods: {
    startNowHandler() {
      this.$router.push({ name: ROUTE_CREATE_ACCOUNT }, null, () => {});
    },

    signInHandler() {
      this.$router.push({ name: ROUTE_LOGIN }, null, () => {});
    }
  }
};
</script>
