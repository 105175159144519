import { getStorage, clearStorage } from '@/modules/localStorage';
import { isTimeExpired } from '@/utils/date';
import {
  ROUTE_CONSENT,
  ROUTE_ABOUT,
  ROUTE_HEALTH_PT_1,
  ROUTE_TODAY,
  // ROUTE_EXACERBATION,
  ROUTE_MEDICATION_HISTORY,
  ROUTE_AUDIO_CALIBRATION,
  ROUTE_SUSTAINED_AAH,
  ROUTE_READING_PASSAGE,
  ROUTE_COUNT_TO_20,
  RECORD_FILE_DATA,
  FILLING_INITIAL_DATA
} from '@/constants';

if (isTimeExpired()) {
  clearStorage();
}

export const initialCharacteristics = {
  dob_month: null, // number
  dob_year: null, // number
  sex: null, // string
  height: null, // centimeters
  ethnicity: null, // string,
  smoke: null, // boolean,
  smoke_value: null, // string,
  vape: null, // boolean,
  vape_value_ml: null, // string,
  vape_value_mg: null, // string,
  covid19: null // string,
};

export const initialCronicConditions = {
  copd: false,
  asthma: false,
  bronchiectasis: false,
  cystic_fibrosis: false,
  pulmonary_fibrosis: false,
  lung_cancer: false,
  anxiety: false,
  congestive_heart_failure: false,
  depression: false,
  sinusitis: false,
  allergic_rhinitis: false,
  acid_reflux: false,
  gerd: false,
  obesity: false,
  pulmonary_hypertension: false,
  pulmonary_nodules: false,
  sarcoidosis: false,
  sleep_apnea: false,
  vocal_cord_dysfunction: false,
  stroke: false
};

export const initialFilling = FILLING_INITIAL_DATA.reduce(
  (acc, { type }) => ({ ...acc, [type]: null }),
  {}
);

export const initialPassed = {
  [ROUTE_CONSENT]: false,
  [ROUTE_ABOUT]: false,
  [ROUTE_HEALTH_PT_1]: false,
  [ROUTE_TODAY]: false,
  // [ROUTE_EXACERBATION]: false,
  [ROUTE_MEDICATION_HISTORY]: false,
  [ROUTE_AUDIO_CALIBRATION]: false,
  [ROUTE_SUSTAINED_AAH]: false,
  [ROUTE_READING_PASSAGE]: false,
  [ROUTE_COUNT_TO_20]: false
};

export const initialUser = {
  participant_id: null,
  last_session_id: null,
  session_id: null,
  email: null,
  token: null,
  user_id: null,
  is_superuser: false,
  superUserSignedIn: false,
  superToken: null,
  full_name: null,
  is_subscribed_text: true,
  is_subscribed_email: true,
  phone: null,
  get_feeling_today: false,
};

export const initialExacerbation = {
  prescriptions: null,
  dosages: null,
  visited_emergency_department: null,
  visited_emergency_department_date: null,
  admitted_to_hospital: null,
  admitted_to_hospital_date: null,
  discharged_date: null
};

export const initialPopUpCount = {
  calibration_noise: 0,
  esnr: 0,
  ahh_effort: 0,
  no_fe: 0,
  insufficient_fe: 0,
  no_cough: 0,
  no_rp: 0,
  no_wl: 0,
  no_feeling_today: 0,
  lost_stream: 0
}

export const initialUploading = Object.keys(RECORD_FILE_DATA).reduce(
  (acc, key) => ({
    ...acc,
    [key]: { loading: false, error: null, uploaded: false }
  }),
  {}
);

export default {
  characteristics: initialCharacteristics,
  chronic_conditions: initialCronicConditions,
  filling: initialFilling,
  loading: false,
  passed: getStorage('passed') || initialPassed,
  user: getStorage('user') || initialUser,
  uploading: getStorage('uploading') || initialUploading,
  sessionHistory: null,
  lastSession: null,
  aiLoading: {},
  popups: {},
  recording: false,
  audioSource: null,
  medication_history: null,
  session_quality: null,
  exacerbation: initialExacerbation,
  StreamerRegistered: false,
  StreamRegistrationPromise: null,
  popUpCount: getStorage('popUpCount') || initialPopUpCount,
  session_metrics: null,
  lungReport: null,
  dashboardData: null,
  streamAPIPayload: null,
  breath_segment_info: getStorage('breath_segment_info') || [],
  inferenceInFlight: getStorage('inferenceInFlight') || false,
};
